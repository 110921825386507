.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sc-iJKOgi {
  background-color: black !important;
  color: black !important;
}

.sc-bYoCGh {
  color: white !important;
}

.sc-cxpSRg .fyqMXA[selected] {
  color: #1fe2a5 !important;
  background-color: #1fe2a5 !important;
}

select {
  background: #000 !important;
  color: white;
  border: 1px solid #1fe2a5 !important;
  border-radius: 5px;
}

.sc-giYfyr .bxjEuD svg {
  color: white !important;
}

.sc-cxpSRg svg {
  color: white !important;
}

.fyqMXA option {
  color: white !important;
}

.sc-llYTIl svg {
  color: white !important;
}

.sc-llYTIl select {
  color: white !important;
}

.sc-ezblnY svg {
  fill: white !important;
}

.sc-ezblnY svg:hover {
  fill: #1fe2a5 !important;
}

.sc-dlVxUE {
  /* border: 1px solid white !important; */
  padding-bottom: 1rem !important;
}

.sc-dlVxUE::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

.sc-dlVxUE::-webkit-scrollbar-thumb {
  background: #1fe2a5;
  border-radius: 20px;
}

.sc-dlVxUE::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

.hide-show-button {
  border-radius: 25px;
  background: linear-gradient(270deg, #169e8e 0%, #1fe2a5 100%);
}
.button-clasic {
  border-radius: 25px;
  background: linear-gradient(270deg, #169e8e 0%, #1fe2a5 100%);
}
.main-bg {
  background: linear-gradient(270deg, #169e8e 0%, #1fe2a5 100%);
}
.gFDfW {
  background: black;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: visible;
  width: 120px;
  background-color: black;
}

.submit-btn {
  border-radius: 15px;
  background: linear-gradient(180deg, #1fe2a5 -1.06%, #169e8e 98.94%);
}
.modal {
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #1fe2a5;
  border-radius: 20px;
}

.modal-content::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

.rdt_TableRow:hover {
  /* background: #169E8E !important; Change to your preferred color */
  cursor: pointer; /* Make the cursor a hand when hovering over the row */
  border-bottom: 1px solid #169e8e !important;
}

.rdt_TableHeadRow,
.rdt_TableBody,
.rdt_TableRow {
  background-color: #000 !important;
}
.rdt_TableRow {
  border-bottom: 1px solid #fff !important;
}
header {
  z-index: 999999 !important;
}

@keyframes borderClosing {
  0% {
    border-color: green;
  }
  100% {
    border-color: transparent;
  }
}

.border-animation {
  position: absolute;
  top: 0;
  width: 100%;
  border: 6px solid transparent;
  border-top-color: green;
  border-radius: 6px 6px 0 0;
  animation: borderClosing 6s forwards; /* Adjust the duration as needed */
}
