@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #333333;
  color: #fff;
}

.border-gradient {
  position: relative;
  overflow: hidden;
 }
 
 .border-gradient::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #1FE2A5, #1FE2A5);
 }